import Api from "../baseApi";
import bonusType from "@/lib/bonusType";

const state = {
    all: [],
    params: {},
};
const actions = {
    getByUserId: async (context, params) => {
        state.params = params;
        var must = [];
        if (params.userId) {
            must.push({
                match: {
                    userId: params.userId
                }
            });
        }

        let query = {
            size: params.size,
            from: params.size * params.page,
            sort: [
                {
                    createdTime: {
                        order: "desc"
                    }
                }
            ],
            query: {
                bool: {
                    must: must
                }
            },

        };
        if (params.bonusType > -1) {
            query.query.bool.must.push({
                match: {
                    type: params.bonusType
                }
            })
        }
        let d = (
            await Api.post(
                `https://api.slotbase.co/search/search/log_bonuses`,
                query
            )
        ).data;
        context.commit("getByUserId", d);
    },
};
const mutations = {
    getByUserId(state, player) {
        let result = {};
        result.totalPage = Math.ceil(player.hits.total.value / state.params.size);
        result.data = player.hits.hits.map((obj) => {
            obj._source.type = bonusType.find((c) => c.key == obj._source.type) ? bonusType.find((c) => c.key == obj._source.type).value : obj._source.type;
            return obj._source;
        });
        state.all = result;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
