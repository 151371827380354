import Api from "../baseApi";

const state = {
  all: [],
  detail: null,
};

const actions = {
  getAll: async (context, params) => {
    let url = `/admin/Products?p=${params.page}&t=50`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
    return d;
  },

  getById: async (context, id) => {
    let url = `/admin/Products/${id}`;
    let d = (await Api.get(url)).data;
    context.commit("getById", d);
  },

  put: async (context, params) => {
    let url = `admin/Products`;
    (await Api.put(url, params)).data;
  },

  post: async (context, params) => {
    let url = `/admin/Products`;
    let d = (await Api.post(url, params)).data;
    context.commit("getAll", d);
    return d;
  },

  delete: async (context, id) => {
    let url = `/admin/Products/${id}`;
    await Api.delete(url).data;
  },
};

const mutations = {
  getAll(state, settings) {
    state.all = settings;
  },

  getById(state, data) {
    state.detail = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
