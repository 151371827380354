import Api from "../baseApi";
const state = {
  all: [],
  detail: {},
  select: { id: -1, value: "All" },
  totalValue: 0,
};
const getters = {
  error: (state) => {
    return state.error;
  },
};
const actions = {
  getAll: async (context, params) => {
    if (!params.endDate) params.endDate = params.startDate;
    let url = `/admin/GameLogs/modeAggs?startDate=${params.startDate}&endDate=${params.endDate}&t=${params.size}&p=${params.page}`;
    let query = {
      "size": 0,
      "query": {
        "bool": {
          "must": [
            {
              "range": {
                "createDate": {
                  "lte": params.endDate
                }
              }
            },
            {
              "range": {
                "createDate": {
                  "gte": params.startDate
                }
              }
            }
          ]
        }
      },
      "aggs": {
        "modeId": {
          "terms": {
            "size": 2147483647,
            "field": "m",
            "missing": 0
          },
          "aggs": {
            "betTotal": {
              "sum": {
                "field": "b"
              }
            },
            "winTotal": {
              "sum": {
                "field": "t"
              }
            },
            "freeSpinWinTotal": {
              "sum": {
                "field": "fs"
              }
            },
            "freeSpinTotal": {
              "sum": {
                "field": "f"
              }
            }
          }
        }
      }
    };
    if (params.userId) {
      query.query.bool.must.push({
        match: {
          u: params.userId
        }
      })
    }
    let d = (await Api.post(url, query)).data;

    context.commit("getAll", {
      values: d,
    });
  },
  getAllWithQuery: async (context, params) => {
    if (!params.endDate) params.endDate = params.startDate;
    var query = {
      size: 10000,
      query: {
        range: {
          createDate: {
            gte: params.startDate,
            lte: params.endDate,
          },
        },
      },
      search_after: [1663569512],
      sort: {
        createDate: "desc",
      },
    };

    let d = (
      await Api.post(
        `https://search-api.slotbase.co/search/Search/log_spin`,
        query
      )
    ).data;

    context.commit("getAllWithQuery", d);
  },
};
const mutations = {
  getAll(state, data) {
    state.all = data.values;
  },
  getAllWithQuery(state, data) {
    state.all = data.values;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
