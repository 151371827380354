import Api from "../baseApi";
const state = {
  all: [],
  detail: {},
  select: { id: -1, value: "All" },
  totalValue: 0,
};
const getters = {
  error: (state) => {
    return state.error;
  },
};
const actions = {
  getAll: async (context, params) => {
    if (!params.endDate) params.endDate = params.startDate;
    if(params.userId===undefined)params.userId=null;

    let url = `/admin/GameLogs/userChipMovement?userId=${params.userId}&startDate=${params.startDate}&endDate=${params.endDate}&t=${params.size}&p=${params.page}`;
    let d = (await Api.get(url)).data;

    context.commit("getAll", {
      values: d,
    });
  },
  getAllWithQuery: async (context, params) => {
    if (!params.endDate) params.endDate = params.startDate;
    var query = {
      size: 10000,
      query: {
        range: {
          createDate: {
            gte: params.startDate,
            lte: params.endDate,
          },
        },
      },
      search_after: [1663569512],
      sort: {
        createDate: "desc",
      },
    };

    let d = (
      await Api.post(
        `https://search-api.slotbase.co/search/Search/log_spin`,
        query
      )
    ).data;

    context.commit("getAllWithQuery", d);
  },
};
const mutations = {
  getAll(state, data) {
    state.all = data.values;
  },
  getAllWithQuery(state, data) {
    state.all = data.values;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
