import Api from "./baseApi";

const state = {
  detail: {},
  all: [],
};

const actions = {
  get: async (context,params) => {
    
    let url = `/admin/Users/LostUsers/${params.format.id}`;
    let d = (await Api.get(url));
    context.commit("get", d);
    return d;
  }
};
const mutations = {
  get(state, settings) {
    state.all = settings;
  },
  getById(state, data) {
    state.detail = data
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
