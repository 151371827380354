import Api from "../baseApi";

const state = {
  all: [],
  params: {},
  select: { id: -1, value: "All" },
  playerSelect: { id: -1, value: "All" },
  platformSelect: { id: -1, value: "All" },
  sitSelect: { id: -1, value: "All" },
  pageItemSelect: { id: 100, value: "100" },
  timeSelect: { id: 60, value: "Last 1 hour" },
};
const getters = {
  player: (state) => {
    return state.player;
  },
};
const actions = {
  getAuthInfoByUserId: async (context, params) => {
    state.params = params;
    let d = (
      await Api.post(`/search/search/log_oauth`, {
        size: params.size,
        from: params.size * params.page,
        sort: [
          {
            ts: {
              order: "desc",
            },
          },
        ],
        query: {
          match: {
            userId: params.userId,
          },
        },
      })
    ).data;
    context.commit("getAuthInfoByUserId", d);
    return state.playersOutDetail;
  },
};
const mutations = {
  getAuthInfoByUserId(state, player) {
    let result = {};
    result.totalPage = Math.ceil(player.hits.total.value / state.params.size);
    result.data = player.hits.hits.map((obj) => {
      return obj._source;
    });
    state.all = result;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
