import Api from "../baseApi";
import baseStore from "../../index";

const state = {
  all: [],
  setting: {},
  generalSetting: {},
};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  pubsubSlotart: async () => {
    let url = `/admin/settings/PubSubReset`;
    await Api.get(url);
  },
  getGeneralSettings: async (context) => {
    let url = `/admin/settings/longSettings`;
    let d = (await Api.get(url)).data;
    context.commit("getGeneralSettings", d);
  },
  updateGeneralSettings: async (context, data) => {
    let url = `/admin/settings/${data.isDefaultValue}`;
    let d = (await Api.post(url, data)).data;
    context.commit("updateGeneralSettings", d);
  },
  getSettings: async (context) => {
    let url = `${baseStore.state.server.url}/api/admin/settings`;
    let d = (await Api.get(url)).data;
    context.commit("getSettings", d);
  },
  postSettings: async (context, { url, settings }) => {
    (await Api.post(`${url}/api/admin/settings`, settings)).data;
    context.commit("postSettings", settings);
  },
};
const mutations = {
  getSettings(state, settings) {
    state.setting = settings;
  },
  getGeneralSettings(state, settings) {
    state.generalSetting = settings;
  },
  postSettings(state, settings) {
    state.setting = settings;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
