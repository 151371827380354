import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "@/firebase/";
import VueFirestore from "vue-firestore";
import JsonViewer from "vue-json-viewer";
import VJsoneditor from "v-jsoneditor";
// import VueLodash from "vue-lodash";
import _ from 'lodash'
import VueTimeago from "vue-timeago";
import moment from "moment";
import i18n from "./i18n";
import "./plugins/chartist";
import userStatus from "@/lib/userStatus";
import bannedReason from "@/lib/bannedReason";
import DetailCard from "@/views/purchases/components/DetailCard";
import defaultConverter from './lib/converter'

Vue.component("DetailCard", DetailCard);
Vue.set(Vue.prototype, '$_', _)

// eslint-disable-next-line no-console
console.log(process.env.NODE_ENV);
// const options = { name: "lodash" }; // customize the way you want to call it
Vue.use(VueTimeago, {
  name: "Timeago", // Component name, `Timeago` by default
  locale: "en", // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    "zh-CN": require("date-fns/locale/zh_cn"),
    ja: require("date-fns/locale/ja"),
  },
  converter: defaultConverter
});
// Vue.use(VueLodash, options);
Vue.use(VJsoneditor);
Vue.config.productionTip = false;
Vue.use(VueFirestore);
Vue.use(JsonViewer);
localStorage.setItem("env", process.env.NODE_ENV);
if (window.slot.localStorage.getItem("user")) {
  store.state.user = window.slot.localStorage.getItem("user");
  store.state.isAuthenticated = true;
}
if (!localStorage.getItem("selectEnvironment"))
  localStorage.setItem(
    "selectEnvironment",
    JSON.stringify({
      id: "prod",
      key: "Prod",
      url: process.env.VUE_APP_API_SERVER,
    })
  );
const env = JSON.parse(localStorage.getItem("selectEnvironment"));
if (window.localStorage.getItem(env.id + ".currentServer")) {
  store.state.server = JSON.parse(
    window.localStorage.getItem(env.id + ".currentServer")
  );
}

Vue.filter("formatMoney", function (value) {
  if (value) {
    return value
      .toFixed(1)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      .replace(".0", "");
  }
  return value;
});
Vue.filter("toCountry", function (value) {
  if (value) {
    const r = slot.countryCodes.find(
      (m) => m.ccode == (value || "").toUpperCase()
    );
    if (r) return r.cname;
    return value;
  }
  return value;
});

Vue.filter("ToSn", function (value) {
  var values = [
    { key: 1, value: "GuestAndroid" },
    { key: 2, value: "GuestIos" },
    { key: 3, value: "Facebook" },
    { key: 4, value: "Apple" },
    { key: 5, value: "Mail" },
    { key: 6, value: "Gmail" },
    { key: 75, value: "Test" },
    { key: 100, value: "Bot" },
  ];
  return values.find((m) => m.key == value).value;
});

Vue.filter("getDateFormat", function (value) {
  return window.slot.date.getDateFormat(value);
});
Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY HH:mm");
  }
});
Vue.filter("formatOnlyDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY");
  }
});
Vue.filter("formatDateSS", function (value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY HH:mm:ss");
  }
});
Vue.filter("formatDateSSS", function (value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY HH:mm:ss") + "." + value.getMilliseconds();
  }
});
Vue.filter("toUserStatus", function (value) {
  return userStatus.find((m) => m.key == value).value;
});
Vue.filter("toBannedReasons", function (value) {
  return value.map((n) => bannedReason.find((m) => m.key == n).value);
});
Vue.filter("toBannedReason", function (value) {
  if (bannedReason.find((m) => m.key == value))
    return bannedReason.find((m) => m.key == value).value;
});
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

// eslint-disable-next-line no-unused-vars
const slot = window.slot;
window.Vue = new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
