import Api from "../baseApi";
const state = {
  all: [],
  detail: {},
  select: { id: -1, value: "All" },
  totalValue: 0,
};
const getters = {
  error: (state) => {
    return state.error;
  },
};
const actions = {
  getAll: async (context, params) => {
    if (!params.endDate) params.endDate = params.startDate;
    if(params.userId===undefined)params.userId=null;

    let url = `/admin/GameLogs/userAuthLog?userId=${params.userId}&startDate=${params.startDate}&endDate=${params.endDate}&t=${params.size}&p=${params.page}`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", {
      values: d,
    });
  },
};
const mutations = {
  getAll(state, data) {
    // eslint-disable-next-line no-console
    console.log("data=",data.values);
    state.all = data.values;
  },
  getAllWithQuery(state, data) {
    state.all = data.values;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
