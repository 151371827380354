<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Purchase</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dates"
            label="Picker in menu"
            prepend-icon="mdi-calendar"
            readonly
            single-line
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="dates" locale="tr-TR" range no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="initialize">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="$store.state.purchases.priceSelect"
        :items="prices"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Price"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="$store.state.purchases.productTypeSelect"
        :items="productTypes"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Product Type"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="$store.state.purchases.platformSelect"
        :items="platforms"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Platform"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="selectedCountry"
        :items="$store.state.purchases.country"
        item-value="k"
        item-text="v"
        @change="initialize"
        hide-details
        label="Country"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="selectedCity"
        :items="$store.state.purchases.city"
        :loading="$store.state.progress"
        item-value="k"
        item-text="v"
        @change="initialize"
        hide-details
        label="City"
      ></v-combobox>

      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      hide-default-footer
      :options.sync="options"
      :items="purchases.data"
      :items-per-page="50"
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <v-icon class="mr-2" @click="detail(item)">edit</v-icon>
      </template>
      <template v-slot:item.users.playTime="{ item }">
        <div v-if="item.users">
          {{ item.users.playTime | getDateFormat }}
        </div>
      </template>
      <template v-slot:item.users.locationInfo.country_long="{ item }">
        <div style="display: flex;">
          <v-avatar size="36" style="margin-right:5px; margin-top:3px;">
            <v-img
              v-if="item.users && item.users.locationInfo"
              :src="
                `https://bundle.slotbase.net/assets/flags/${item.users.locationInfo.countryCode.toLocaleLowerCase()}.png`
              "
            />
          </v-avatar>
          <div v-if="item.users && item.users.deviceInfo.ipAdress">
            <span v-if="item.users.locationInfo">{{
              item.users.locationInfo.country_long
            }}</span>
            <div>{{ item.users.deviceInfo.ipAdress.split(",")[0] }}</div>
          </div>
        </div>
      </template>
      <template v-slot:item.type="{ item }">
        <v-avatar size="36" style="margin-right:5px; margin-top:3px;">
          <v-img
            :src="`https://bundle.slotbase.net/assets/product/${item.type}.png`"
          />
        </v-avatar>
        {{ item.type }}
      </template>
      <template v-slot:item.users.chipAmount="{ item }">
        <div v-if="item.users">
          {{ item.users.chipAmount | formatMoney }}
        </div>
      </template>

      <template v-slot:item.chip="{ item }">
        {{ item.chip | formatMoney }}
      </template>

      <template v-slot:item.totalChip="{ item }">
        {{ item.totalChip | formatMoney }}
      </template>

      <template v-slot:item.createdDate="{ item }">
        <div>{{ new Date(item.createdDate * 1000) | formatDate }}</div>
      </template>

      <template v-slot:item.countryName="{ item }">
        <div>{{ item.users.locationInfo.countryName }}</div>
      </template>

      <template v-slot:item.continentName="{ item }">
        <div>{{ item.users.locationInfo.continentName }}</div>
      </template>

      <template v-slot:item.users.id="{ item }">
        <UserDetailCard
          :userId="item.users.id"
          :pictureUrl="item.users.pictureUrl"
          :userName="item.users.userName"
          :level="item.users.levelInfo.l"
        />
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        :total-visible="20"
        @input="pageClick"
        :length="purchases.totalPage"
      ></v-pagination>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="1600px">
        <v-card>
          <v-card-title>
            <span class="headline">User Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="$store.state.purchases.detail.id"
                    label="Legal first name*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="$store.state.purchases.detail.id"
                    label="Id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="$store.state.purchases.detail.chip"
                    label="Chip"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="$store.state.purchases.detail.price"
                    label="Price"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-if="$store.state.purchases.detail.users"
                    v-model="$store.state.purchases.detail.users.id"
                    label="userId"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-if="$store.state.purchases.detail.users"
                    v-model="$store.state.purchases.detail.users.userName"
                    label="userName"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <json-viewer
              name="df"
              :value="$store.state.purchases.detail.data"
              :expand-depth="5"
              theme="my-awesome-json-theme"
              sort
            ></json-viewer>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "DetailCard",
  props: {
    search: {
      type: String,
      description: "purchases",
    },
  },
  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },
  data() {
    return {
      options: {},
      dates: [
        new Date(Date.now())
          .addDays(-30)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now()).toISOString().substr(0, 10),
      ],

      menu: false,
      dialog: false,
      currentPage: 1,
      selectedCity: "",
      selectedCountry: "",
      purchases: {},
      productTypes: [
        { id: -1, value: "All" },
        { id: 0, value: "Chip" },
        { id: 1, value: "Gems" },
        { id: 2, value: "PromoCode" },
        { id: 3, value: "Multi" },
        { id: 4, value: "SuperVegasWheel" },
        { id: 5, value: "Daruma" },
        { id: 6, value: "Treasure" },
        { id: 7, value: "CoinFlip" },
        { id: 8, value: "WheelOfFortune" },
        { id: 9, value: "Piggy" },
        { id: 10, value: "MultiplierWheel" },
        { id: 11, value: "MultiplierTreasure" },
      ],
      platforms: [
        { id: "", value: "All" },
        { id: "android", value: "Android" },
        { id: "ios", value: "Ios" },
        { id: "facebook", value: "Facebook" },
      ],
      prices: [
        { id: -1, value: "All" },
        { id: 1.49, value: "1.49" },
        { id: 2.99, value: "2.99" },
        { id: 3.75, value: "3.75" },
        { id: 4.99, value: "4.99" },
        { id: 7.49, value: "7.49" },
        { id: 7.99, value: "7.99" },
        { id: 9.99, value: "9.99" },
        { id: 14.99, value: "14.99" },
        { id: 19.99, value: "19.99" },
        { id: 24.99, value: "24.99" },
        { id: 37.49, value: "37.49" },
        { id: 49.99, value: "49.99" },
        { id: 74.99, value: "74.99" },
        { id: 99.99, value: "99.99" },
        { id: 149.99, value: "149.99" },
      ],

      headers: [
        { text: "User", value: "users.id" },
        {
          text: "Id",
          value: "id",
        },
        { text: "Product Type", value: "type" },
        { text: "Platform", value: "platform" },
        { text: "Chip", value: "chip" },
        { text: "TotalChip", value: "totalChip" },
        { text: "Price", value: "price" },
        { text: "Chip Amount", value: "users.chipAmount" },
        { text: "Play Time", value: "users.playTime" },
        { text: "Created Date", value: "createdDate" },
        { text: "Country", value: "countryName" },
        { text: "City", value: "continentName" },
        { text: "State", value: "state" },
        { text: "Actions", value: "action", sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.options.sortBy.length > 0) this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    // async save() {
    //   await this.$store.dispatch("purchases/post", this.detail);
    // },
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
    async initialize() {
      this.$refs.menu.save(this.dates);
      // eslint-disable-next-line no-console
      console.log(this.selectedCountry);
      this.purchases = await this.$store.dispatch("purchases/getAll", {
        page: this.currentPage - 1,
        search: this.search,
        options: this.options,
        country: this.selectedCountry.v,
        city: this.selectedCity.v,
        startDate:
          new Date(
            new Date(this.dates[0]).getTime() +
              new Date().getTimezoneOffset() * 60000
          ) / 1000,
        endDate: new Date(this.dates[1]).addDays(1).getTime() / 1000,
      });
    },
    async detail(item) {
      await this.$store.dispatch("purchases/get", item.id);
      this.dialog = true;
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>
