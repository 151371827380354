import Api from "../baseApi";

const state = {
  all: [],
  detail: {},
};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  getAll: async (context) => {
    let url = `/product/productPromos`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
  },
  createOrUpdate: async (context, data) => {
    let url = `/product/productPromos`;
    (await Api.post(url, data)).data;
  },
  delete: async (context, id) => {
    Api.delete(`/product/productPromos/${id}`);
  },
  get: async (context, id) => {
    let url = `/product/productPromos/${id}`;
    let data = (await Api.get(url)).data;
    context.commit("get", data);
  },
};
const mutations = {
  getAll(state, settings) {
    state.all = settings;
  },
  get(state, data) {
    state.detail = data;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
