import Api from "../baseApi";

const state = {
  all: [],
};

const actions = {
  getAll: async (context, params) => {
    if (!params.endDate) params.endDate = params.startDate;
    var query = {
      size: 10000,
      query: {
        bool: {
          must: [
            {
              range: {
                createDate: {
                  gte: params.startDate,
                  lte: params.endDate,
                },
              },
            },
            {
              match: {
                m: params.modeId,
              },
            },
          ],
        },
      },
    };
    let d = (
      await Api.post(
        `https://search-api.slotbase.co/search/Search/log_spin`,
        query
      )
    ).data;

    context.commit("getAll", d);
  },
  getAllFromBackend: async (context, params) => {
    if (!params.endDate) params.endDate = params.startDate;
    let url = `/admin/GameLogs/modeDetails?modeId=${params.modeId}&startDate=${params.startDate}&endDate=${params.endDate}&t=${params.size}&p=${params.page}`;

    let d = (await Api.get(url)).data;

    context.commit("getAllFromBackend", {
      values: d,
    });
  },
  getAllLevelUserDetails: async (context, params) => {
    let url = `/admin/GameLogs/modeLevelUserDetails?t=${params.size}&p=${params.page}`;

    if (params.date > -1) url += `&date=${params.date}`;
    if (params.modeType > -999) url += `&modeType=${params.modeType}`;

    let d = (await Api.get(url)).data;

    context.commit("getAllLevelUserDetails", {
      values: d,
    });
  },
};

const mutations = {
  getAll(state, data) {
    state.all = data.hits.hits.map((o) => {
      return o._source.sr;
    });
  },
  getAllFromBackend(state, data) {
    state.all = data.values;
  },
  getAllLevelUserDetails(state, data) {
    state.all = data.values;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
