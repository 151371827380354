import Api from "../baseApi";

const state = {
  all: [],
  detail: {},
  country: [],
  countrySales: {},
  countrySalesList: {},
  city: [],
  dailySales: [],
  platformTotalSales: [],
  citySelect: { id: "0", value: "All" },
  countrySelect: { id: "0", value: "All" },
  productTypeSelect: { id: -1, value: "All" },
  priceSelect: { id: -1, value: "All" },
  platformSelect: { id: "", value: "All" },
};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  getAll: async (context, params) => {
    // eslint-disable-next-line no-console
    if (!params.endDate) params.endDate = params.startDate;
    let url = `/admin/purchases?startDate=${params.startDate}&endDate=${params.endDate}&q=${params.search}&p=${params.page}&t=50`;
    if (
      params.city !== undefined &&
      params.city !== "All" &&
      params.city !== null
    ) {
      url += `&city=${params.city}`;
    }

    if (
      params.country !== undefined &&
      params.country !== "All" &&
      params.country !== null
    )
      url += `&country=${params.country}`;

    if (state.priceSelect.id > -1) url += `&price=${state.priceSelect.id}`;

    if (state.productTypeSelect.id > -1)
      url += `&productType=${state.productTypeSelect.id}`;

    if (state.platformSelect.id != "")
      url += `&platform=${state.platformSelect.id}`;

    if (params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.get(url)).data;
    context.commit("getAll", d);

    return d;
  },
  getByDailySales: async (context, params) => {
    let url = `/admin/purchases/GetByDailySales/${params.startDate}/${params.endDate}`;
    let d = (await Api.get(url)).data;
    context.commit("getByDailySales", d);
  },
  getPlatformTotalSales: async (context, params) => {
    let url = `/admin/purchases/GetPlatformTotalSales`;
    if (params)
      url += `?startDate=${params.startDate}&endDate=${params.endDate}`;

    let d = (await Api.get(url)).data;
    context.commit("getPlatformTotalSales", d);
    return d;
  },
  getByCountrySales: async (context) => {
    let url = `/admin/purchases/GetByCountrySales`;
    let d = (await Api.get(url)).data;
    context.commit("getByCountrySales", d);
  },
  getCountry: async (context) => {
    let d = (await Api.get(`/admin/purchases/country`)).data;
    context.commit("getCountry", d);
  },
  getCity: async (context) => {
    let d = (await Api.get(`/admin/purchases/city`)).data;
    context.commit("getCity", d);
  },
  // post: async (context, params) => {
  //   let url = `/admin/purchases`;
  //   let d = (await Api.post(url, params)).data;
  //   return d;
  // },
  // put: async (context, params) => {
  //   let url = `/admin/purchases`;
  //   let d = (await Api.put(url, params)).data;
  //   return d;
  // },
  get: async (context, id) => {
    context.commit("get", id);
  },
};
const mutations = {
  getPlatformTotalSales(state, settings) {
    state.platformTotalSales = settings;
  },
  getByCountrySales(state, settings) {
    state.countrySalesList = settings;
    settings.forEach((element) => {
      state.countrySales[(element.countryCode || "").toUpperCase()] =
        element.total;
    });
    // eslint-disable-next-line no-console
    console.log(state.countrySales);
  },
  getByDailySales(state, settings) {
    state.dailySales = settings;
  },
  getAll(state, settings) {
    state.all = settings;
  },
  get(state, id) {
    state.detail = state.all.data.find((m) => m.id == id);
  },
  getCountry(state, country) {
    state.country = [{ k: "0", v: "All" }, ...country];
  },
  getCity(state, city) {
    state.city = [{ k: "0", v: "All" }, ...city];
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
