import Api from "./baseApi";
import baseStore from "../index";

const state = {
  all: [],
  room: {},
  roomFilters: [],
  roomTypes: [],
  startPlaySelect: { id: -1, value: "All" },
  roomFilterSelect: { minBuy: "All" },
  startGameSelect: { id: -1, value: "All" },
  isEnabledSelect: { id: -1, value: "All" },
  isPrivateSelect: { id: -1, value: "All" },
  sitFilledSelect: { id: 0, value: "Filed" },
  roomTypeSelect: { id: -1, value: "All" },
  pageItemSelect: { id: 100, value: "100" },

};
const getters = {
  room: (state) => {
    return state.room;
  },
  getRoomFilters: (state) => {
    return state.roomFilters;
  },
};
const actions = {
  getRooms: async (context, params) => {
    let url = `${baseStore.state.server.url}/api/admin/rooms?q=${params.search
      }&sitsFilled=${state.sitFilledSelect.id}&p=${params.page - 1}&t=${state.pageItemSelect.id
      }`;
    if (state.startPlaySelect.id > -1)
      url += `&isPlaying=${state.startPlaySelect.id}`;
    if (state.isEnabledSelect.id > -1)
      url += `&isEnable=${state.isEnabledSelect.id}`;
    if (state.isPrivateSelect.id > -1)
      url += `&isPrivate=${state.isPrivateSelect.id}`;
    if (state.startGameSelect.id > -1)
      url += `&isStartGame=${state.startGameSelect.id}`;
    if (state.roomTypeSelect.id > -1)
      url += `&roomType=${state.roomTypeSelect.id}`;
    if (state.roomFilterSelect.minBuy !== "All")
      url += `&minBuy=${state.roomFilterSelect.minBuy}`;

    let d = (await Api.get(url)).data;
    context.commit("getRooms", d);
  },
  getRoom: async (context, id) => {
    let d = (
      await Api.get(`${baseStore.state.server.url}/api/admin/rooms/${id}`)
    ).data;
    context.commit("getRoom", d);
    return d;
  },
  getRoomTypes: async (context) => {
    let d = (
      await Api.get(`${baseStore.state.server.url}/api/admin/rooms/GetRoomType`)
    ).data;
    context.commit("getRoomTypes", d);
  },
  getRoomFilters: async (context) => {
    let d = (
      await Api.get(`${baseStore.state.server.url}/api/admin/rooms/roomfilters`)
    ).data;
    context.commit("getRoomFilters", d);
  },
  clearNullPlayer: async (context, id) => {
    return (
      await Api.get(
        `${baseStore.state.server.url}/api/admin/rooms/clearNullPlayer/${id}`
      )
    ).data;
  },
  setTax: async (context, data) => {
    return (
      await Api.post(
        `${baseStore.state.server.url}/api/admin/rooms/postTaxLimit`, data
      )
    ).data;
  },
  disconnectPlayer: async (context, id) => {
    return (
      await Api.get(
        `${baseStore.state.server.url}/api/admin/rooms/disconnectRoomPlayer?id=${id}`
      )
    ).data;
  },
  allDisconnectPlayer: async (context, item) => {
    return (
      await Api.get(`${item.url}/api/admin/rooms/allDisconnectRoomPlayer`)
    ).data;
  },
  changeEnabled: async (context, item) => {
    (
      await Api.post(
        `${baseStore.state.server.url}/api/admin/rooms/RoomStatus/${item.info.roomId}/${item.isEnable}`
      )
    ).data;
  },
};
const mutations = {
  getRooms(state, rooms) {
    state.all = rooms;

    localStorage.setItem(
      "rooms.startPlaySelect",
      JSON.stringify(state.startPlaySelect)
    );
    localStorage.setItem(
      "rooms.roomFilterSelect",
      JSON.stringify(state.roomFilterSelect)
    );
    localStorage.setItem(
      "rooms.startGameSelect",
      JSON.stringify(state.startGameSelect)
    );
    localStorage.setItem(
      "rooms.sitFilledSelect",
      JSON.stringify(state.sitFilledSelect)
    );
    localStorage.setItem(
      "rooms.roomTypeSelect",
      JSON.stringify(state.roomTypeSelect)
    );
    localStorage.setItem(
      "rooms.pageItemSelect",
      JSON.stringify(state.pageItemSelect)
    );
  },
  getParameters() {
    const prefix = "rooms.";
    [
      "pageItemSelect",
      "roomTypeSelect",
      "sitFilledSelect",
      "startGameSelect",
      "roomFilterSelect",
      "startPlaySelect",
    ].forEach((element) => {
      var elem = localStorage.getItem(prefix + element);
      if (elem) state[element] = JSON.parse(elem);
    });
  },
  getRoom(state, room) {
    Object.assign(state.room, room, {
      length: room.length,
    });
  },
  getRoomTypes(state, roomTypes) {
    roomTypes.push({ id: -1, value: "All" });
    Object.assign(
      state.roomTypes,
      roomTypes.sort((a, b) => a.id - b.id),
      {
        length: roomTypes.length,
      }
    );
  },
  getRoomFilters(state, room) {
    Object.assign(state.roomFilters, [{ minBuy: "All" }, ...room], {
      length: room.length,
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
