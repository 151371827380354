import Api from "../baseApi";

const state = {
  all: [],
  scoreSelect: { key: -1, value: "All" },
};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  getAll: async (context, params) => {
    let url = `/admin/Feedbacks?q=${params.search}&p=${params.page}&t=100`;
    if (state.scoreSelect.key > -1) url += `&score=${state.scoreSelect.key}`;
    // if (params.options.sortBy[0])
    //   url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
    return d;
  },
};
const mutations = {
  getAll(state, settings) {
    state.all = settings;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
