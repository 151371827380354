import Api from "../baseApi";
import baseStore from "../../index";
import bonusType from "@/lib/bonusType";

const state = {
  all: { players: [] },
  playersOut: [],
  countryGroups: [],
  playersOutDetail: [],
  player: {},
  select: { id: -1, value: "All" },
  playerSelect: { id: -1, value: "All" },
  platformSelect: { id: -1, value: "All" },
  sitSelect: { id: -1, value: "All" },
  pageItemSelect: { id: 50, value: "50" },
  timeSelect: { id: 60, value: "Last 1 hour" },
  params: {}
};
const getters = {
  player: (state) => {
    return state.player;
  },
};
const actions = {
  getPlayers: async (context, params) => {
    // eslint-disable-next-line no-console
    //&page=1&itemsPerPage=50&sortBy=&sortDesc
    let url = `${baseStore.state.server.url}/api/admin/players?q=${params.search}&page=${params.page}&itemsPerPage=${state.pageItemSelect.id}&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`; //${state.pageItemSelect.id} ${params.page - 1}
    if (state.select.id > -1) url += `&isDisconnect=${state.select.id}`;
    if (state.playerSelect.id > -1) url += `&isBot=${state.playerSelect.id}`;
    if (state.sitSelect.id > -1) url += `&isSitIn=${state.sitSelect.id}`;
    if (params.os) url += `&os=${params.os}`;
    if (params.flag) url += `&flag=${params.flag}`;
    if (state.platformSelect.id > -1)
      url += `&platformid=${state.platformSelect.id}`;
    // eslint-disable-next-line no-console
    console.log(baseStore.state.server.url);
    let d = (await Api.get(url)).data;
    context.commit("getPlayers", {
      data: d,
      params: params,
    });
  },
  jsonToQueryString(json) {
    return (
      "&" +
      Object.keys(json)
        .map(function (key) {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
          );
        })
        .join("&")
    );
  },
  getOverSitInData: async () => {
    await Api.get(`${baseStore.state.server.url}/api/admin/settings/OverSitInData`);
  },


  getPlayerOutByUserId: async (context, id) => {
    let d = (
      await Api.post(`https://api.slotbase.co/search/search/log_playerio`, {
        size: 2000,
        sort: [
          {
            connectTime: {
              order: "desc",
            },
          },
        ],
        query: {
          match: {
            userId: `${id}`,
          },
        },
      })
    ).data;
    context.commit("getPlayerOutByUserId", d);
    return state.playersOutDetail;
  },
  getPlayerOut: async (context, params) => {
    state.params = params;
    let query = {
      query: {
        bool: {
          must: [],
        },
      },
      size: state.pageItemSelect.id,
      from: params.page * state.pageItemSelect.id,
      sort: [
        {
          connectTime: {
            order: "desc",
          },
        },
      ],
    };
    if (params.search) {
      query.query.bool.must.push({
        match_phrase_prefix: {
          userId: params.search,
        },
      });
    }
    // if (params.date) {
    //   let dt = new Date(params.date);
    //   // dt.setMinutes(dt.getMinutes() + 30);
    //   let date = Math.round(dt.getTime() / 1000);
    //   query.query.bool.must.push({
    //     range: {
    //       connectTime: {
    //         gte: date
    //       }
    //     }
    //   });
    // }
    if (state.timeSelect.id > -1) {
      let dt = new Date();
      dt.setMinutes(dt.getMinutes() - state.timeSelect.id);
      let date = Math.round(dt.getTime() / 1000);
      query.query.bool.must.push({
        range: {
          connectTime: {
            gte: date,
          },
        },
      });
    }
    let d = (
      await Api.post(
        `https://api.slotbase.co/search/search/log_playerio`,
        query
      )
    ).data;
    context.commit("getPlayerOut", d);
  },
  disconnectPlayer: async (context, id) => {
    return (
      await Api.get(
        `${baseStore.state.server.url}/api/admin/players/disconnectPlayer/${id}`
      )
    ).data;
  },
  sendMessage: async (context, model) => {
    return (
      await Api.post(
        `${baseStore.state.server.url}/api/admin/players/message/${model.id}`,
        model
      )
    ).data;
  },
  // eslint-disable-next-line no-unused-vars
  overSitInData: async (context, model) => {
    return (
      await Api.get(
        `${baseStore.state.server.url}/api/admin/settings/overSitInData`
      )
    ).data;
  },

  // eslint-disable-next-line no-unused-vars
  addJackPot: async (context, model) => {
    return (
      await Api.get(
        `${baseStore.state.server.url}/api/admin/rooms/addJackPot/1000001/500000000000`
      )
    ).data;
  },
  sendSlotGift: async (context, model) => {
    return (
      await Api.post("/admin/gifts/slotGift",
        model
      )
    ).data;
  },
};
const mutations = {
  getPlayers(state, input) {
    input.data.players.map((a) => {
      if (a.user.picture && a.user.picture.startsWith("/"))
        a.user.picture = "https://bundle.slotbase.net" + a.user.picture;
      else if (a.user.picture && !a.user.picture.startsWith("http"))
        a.user.picture =
          "https://bundle.slotbase.net/assets/Avatars/" + a.user.picture;
      a.bonusChipsCount = a.bonusChips.reduce((a, b) => a + b.chip, 0);
      a.bonusChips.map((b) => {
        if (bonusType.find((c) => c.key == b.bonusType))
          b.bonusType = bonusType.find((c) => c.key == b.bonusType).value;
      });
    });

    state.all = input.data;
    // state.all.totalPage = Math.ceil(
    //   state.all.players.length / state.pageItemSelect.id
    // );
    localStorage.setItem("players.select", JSON.stringify(state.select));
    localStorage.setItem(
      "players.playerSelect",
      JSON.stringify(state.playerSelect)
    );
    localStorage.setItem("players.sitSelect", JSON.stringify(state.sitSelect));
    localStorage.setItem(
      "players.pageItemSelect",
      JSON.stringify(state.pageItemSelect)
    );
    localStorage.setItem(
      "players.platformSelect",
      JSON.stringify(state.platformSelect)
    );

  },
  getParameters() {
    const prefix = "players.";
    [
      "select",
      "playerSelect",
      "sitSelect",
      "platformSelect",
      "pageItemSelect",
    ].forEach((element) => {
      var elem = localStorage.getItem(prefix + element);
      if (elem) state[element] = JSON.parse(elem);
    });
  },
  getPlayer(state, player) {
    state.player = player;
    // Object.assign(state.player, player, { length: player.length });
  },
  getPlayerOut(state, player) {
    let result = {};
    result.totalPage = Math.ceil(player.hits.total.value / state.pageItemSelect.id);
    result.data = player.hits.hits.map((obj) => {
      return obj._source;
    });
    result.data.map((a) => {
      if (a.picture && a.picture.startsWith("/"))
        a.picture = "https://bundle.slotbase.net" + a.picture;
      else if (a.picture && !a.picture.startsWith("http"))
        a.picture = "https://bundle.slotbase.net/assets/Avatars/" + a.picture;

      if (a.bonusRewards) {
        a.bonusRewardsCount = a.bonusRewards.reduce((a, b) => a + b.chip, 0);
        a.bonusRewards.map((b) => {
          if (bonusType.find((c) => c.key == b.bonusType))
            b.bonusType = bonusType.find((c) => c.key == b.bonusType).value;
        });
      }

    });
    state.playersOut = result;
  },
  getPlayerOutByUserId(state, player) {
    player = player.hits.hits.map((obj) => {
      return obj._source;
    });
    player.map((a) => {
      if (a.picture && a.picture.startsWith("/"))
        a.picture = "https://bundle.slotbase.net" + a.picture;
      else if (a.picture && !a.picture.startsWith("http"))
        a.picture = "https://bundle.slotbase.net/assets/Avatars/" + a.picture;
      if (a.bonusRewards) {
        a.bonusRewardsCount = a.bonusRewards.reduce((a, b) => a + b.chip, 0);
        a.bonusRewards.map((b) => {
          if (bonusType.find((c) => c.key == b.bonusType))
            b.bonusType = bonusType.find((c) => c.key == b.bonusType).value;
        });
      }
    });
    state.playersOutDetail = player;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
