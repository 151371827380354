import Api from "./baseApi";

const state = {
  all: [],
  detail: {},
  cash: 0,
  redirectServer: {},
};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  getAll: async (context) => {
    let url = `/admin/dealer`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
  },
  update: async (context, data) => {
    let url = `/admin/dealer/${data.id}/${data.value}`;
    let d = (await Api.put(url, data)).data;
    context.commit("update", d);
  },
  get: async (context, id) => {
    context.commit("get", id);
  },

};
const mutations = {
  getRedirect(state, settings) {
    state.redirectServer = settings;
  },
  getAll(state, settings) {
    state.cash = settings.cash.highCardFlush.reduce((a, b) => a + b.value, 0)
      + settings.cash.ultimate.reduce((a, b) => a + b.value, 0)
      + settings.cash.ridem.reduce((a, b) => a + b.value, 0)
      + settings.cash.trio.reduce((a, b) => a + b.value, 0)
      + settings.cash.spinSlotBase.reduce((a, b) => a + b.value, 0)
      + settings.cash.holdemBonus.reduce((a, b) => a + b.value, 0);
    state.jackPot = settings.jackPot.highCardFlush.reduce((a, b) => a + b.value, 0)
      + settings.jackPot.holdem.reduce((a, b) => a + b.value, 0)
      + settings.jackPot.spinSlotBase.reduce((a, b) => a + b.value, 0)
      + settings.jackPot.holdemBonus.reduce((a, b) => a + b.value, 0);
    state.all = settings;
  },
  get(state, id) {
    state.detail = state.all.find((m) => m.id == id);
  },
  delete(state, id) {
    state.all = state.all.filter((m) => m.id != id);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
