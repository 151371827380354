import axios from "axios";
import store from "@/store";
// import { authenticationService } from "@/_services";
// const tenantId =
//   authenticationService.currentUserValue == null
//     ? null
//     : authenticationService.currentUserValue.tenantId;
const Api = axios.create({
  //baseURL: "https://slot1.slotbase.net/api",
  baseURL:"https://localhost:7223",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    pw:
      "4bb1f98873a6c84305fa01674bbac87341f988734305fa01674bb1f988734305fa0167",
    Authorization: "Bearer " + window.slot.localStorage.getItem("_lat"),
  },
});

Api.interceptors.request.use((c) => {
  if (c.url.indexOf("api.slotbase") == -1)
    c.baseURL = JSON.parse(localStorage.getItem("selectEnvironment")).url;
  store.commit("ADD_PENDING", c.url);
  return c;
});

Api.interceptors.response.use(
  (c) => {
    store.commit("REMOVE_PENDING", c.request.responseURL);
    return c;
  },
  function (error) {
    store.commit("REMOVE_PENDING", error.request.responseURL);

    if (401 === error.response.status) {
      // window.location = "/sign-in";
    } else {
      return Promise.reject(error);
    }
  }
);

export default Api;
