import Api from "./baseApi";

const state = {
  detail: {},
  all: [],
};

const actions = {
  getAll: async (context,params) => {
    if (!params.endDate) params.endDate = params.startDate;
    if (params.userId===undefined) 
    {
      params.userId="";
    }

    if (params.level===undefined) 
    {
      params.level=0;
    }

    let url = `/admin/GameLogs/modeStatistic?userId=${params.userId}&startDate=${params.startDate}&endDate=${params.endDate}&level=${params.level}`;
    let d = (await Api.get(url));
    context.commit("getAll", d);
    return d;
  },
};
const mutations = {
  getAll(state, settings) {
    state.all = settings;
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
