import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { firebase } from "@firebase/app";
import "firebase/auth";
import router from "@/router";
// import VuexPersist from "vuex-persist";
import rooms from "./modules/rooms";
import players from "./modules/users/players";
import setting from "./modules/settings/index";
const { settings, configs } = setting; // Works
import lobbies from "./modules/lobbies";
import notifications from "./modules/notifications";
import homes from "./modules/homes";
import errors from "./modules/logs/errors";
import purchases from "./modules/products/purchases";
import pictureUploads from "./modules/pictureUploads";
import servers from "./modules/servers";
import apis from "./modules/apis";
import userReports from "./modules/users/userReports";
import users from "./modules/users/users";
import gameLogs from "./modules/logs/gameLogs";
import modeLogs from "./modules/logs/modeLogs";
import modeAggregateLogs from "./modules/logs/modeAggregateLogs";
import modeLogDetails from "./modules/logs/modeLogDetails";
import productPromos from "./modules/products/productPromos";
import chipTransfers from "./modules/logs/chipTransfers";
import feedbacks from "./modules/users/feedbacks";
import logAuth from "./modules/logs/logAuth";
import taxpays from "./modules/users/taxpays";
import cashpays from "./modules/users/cashpays";
import sitBlocks from "./modules/users/sitBlocks";
import bonusLogs from "./modules/logs/bonusLogs";
import specialWinner from "./modules/logs/specialWinner";
import amountIo from "./modules/logs/amountIo";
import dealer from "./modules/dealer";
import botDetection from "./modules/botDetection";
import gameModes from "./modules/gameModes";
import products from "./modules/products/products";
import seasonPass from "./modules/seasonPass";
import slotEvents from "./modules/slotEvents";
import nowTrends from "./modules/nowTrends";
import userModeAggregateLogs from "./modules/logs/userModeAggregateLogs";
import campaigns from "./modules/campaigns";
import modeRtp from "./modules/modeRtp";    
import userChipMovements from "./modules/logs/userChipMovements";   
import userChipLogs from "./modules/logs/userChipLogs";
import userAuthLogs from "./modules/logs/userAuthLogs";
import retentions from "./modules/retentions";
import modeAnalytics from "./modules/modeAnalytics";
import purchaseLogs from "./modules/purchaseLogs";
import lostUsers from "./modules/lostUsers";


// const vuexPersist = new VuexPersist({
//   key: "my-app",
//   storage: window.localStorage
// });
Vue.use(Vuex);
export const mutationTypes = {
  ADD_PENDING: "ADD_PENDING",
  REMOVE_PENDING: "REMOVE_PENDING",
};

export default new Vuex.Store({
  modules: {
    rooms,
    players,
    settings,
    configs,
    lobbies,
    notifications,
    homes,
    sitBlocks,
    errors,
    bonusLogs,
    taxpays,
    cashpays,
    purchases,
    pictureUploads,
    servers,
    apis,
    userReports,
    dealer,
    users,
    gameLogs,
    modeLogs,
    modeAggregateLogs,
    modeLogDetails,
    amountIo,
    productPromos,
    chipTransfers,
    feedbacks,
    logAuth,
    specialWinner,
    botDetection,
    gameModes,
    products,
    seasonPass,
    nowTrends,
    slotEvents,
    userModeAggregateLogs,
    campaigns,
    modeRtp,
    userChipMovements,
    userChipLogs,
    userAuthLogs,
    retentions,
    modeAnalytics,
    purchaseLogs,
    lostUsers
  },
  state: {
    user: null,
    isAuthenticated: false,
    pendings: [],
    progress: false,
    isAlert: false,
    alertMessage: "Success.",
    server: {
      url:
        process.env.NODE_ENV == "development" 
          ? "http://slot1.slotbase.net"
          : "https://slot1.slotbase.net",
    },
  },
  // plugins: [vuexPersist.plugin],
  mutations: {
    setAlert(state) {
      state.isAlert = true;
      setTimeout(() => (state.isAlert = false), 3000);
    },
    setUser(state, payload) {
      state.user = payload;
      // eslint-disable-next-line no-console
      console.log(payload);

      window.slot.localStorage.setItem("_lat", payload.user._lat);
      window.slot.localStorage.setItem("user", JSON.stringify(payload));
    },
    changeServer(state, payload) {
      Object.assign(state.server, payload, { length: payload.length });
      const env = JSON.parse(localStorage.getItem("selectEnvironment"));
      // eslint-disable-next-line no-console
      console.log(env.id);
      window.localStorage.setItem(
        env.id + ".currentServer",
        JSON.stringify(payload)
      );
    },
    setIsAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
    [mutationTypes.ADD_PENDING](state, pending) {
      state.progress = true;
      state.pendings.push(pending);
    },
    [mutationTypes.REMOVE_PENDING](state, pending) {
      state.progress = false;
      state.pendings.splice(state.pendings.indexOf(pending), 1);
    },
  },
  actions: {
    backRouter() {
      window.history.go(-1);
    },
    setAlert({ commit }) {
      commit("setAlert");
    },
    async changeServer({ commit }, server) {
      commit("changeServer", server);
    },
    async getRecipes({ state, commit }, plan) {
      try {
        let response = await axios.get(`${state.apiUrl}`, {
          params: {
            q: plan,
            app_id: "5b6623d5",
            app_key: "46674aa2193dbb7b88ffd897331e661a",
            from: 0,
            to: 9,
          },
        });
        // sbdinc keys
        // let response = await axios.get(`${state.apiUrl}`, {
        //     params: {
        //         q: plan,
        //         app_id: '903de977',
        //         app_key: '1b5fbf78de2db637b392f141c524222c\t',
        //         from: 0,
        //         to: 9
        //     }
        // });
        commit("setRecipes", response.data.hits);
      } catch (error) {
        commit("setRecipes", []);
      }
    },
    userLogin({ commit }, { email, password }) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          commit("setUser", user);
          commit("setIsAuthenticated", true);
          window.location = "/";
        })
        .catch(() => {
          commit("setUser", null);
          commit("setIsAuthenticated", false);
          router.push("/");
        });
    },
    userJoin({ commit }, { email, password }) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          commit("setUser", user);
          commit("setIsAuthenticated", true);
          router.push("/");
        })
        .catch(() => {
          commit("setUser", null);
          commit("setIsAuthenticated", false);
          router.push("/");
        });
    },
    userSignOut({ commit }) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          commit("setIsAuthenticated", false);
          window.slot.localStorage.removeItem("user");
          window.slot.localStorage.removeItem("_lat");
          router.push("/sign-in");
        })
        .catch(() => {
          commit("setIsAuthenticated", false);
          window.slot.localStorage.removeItem("user");
          window.slot.localStorage.removeItem("_lat");

          router.push("/sign-in");
        });
    },
    setIsAuthenticated({ commit }, auth) {
      commit("setIsAuthenticated", auth);
    },
    addRecipe({ state }, payload) {
      firebase
        .database()
        .ref("users")
        .child(state.user.user.uid)
        .push(payload.recipe.label);
    },
    getUserRecipes({ state, commit }) {
      return firebase
        .database()
        .ref("users/" + state.user.user.uid)
        .once("value", (snapshot) => {
          commit("setUserRecipes", snapshot.val());
        });
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.user !== null && state.user !== undefined;
    },
  },
  strict: false, //debug
});
