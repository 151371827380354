import Api from "../baseApi";
const state = {
  all: [],
  detail: {},
  versions: [],
  select: { id: -1, value: "All" },
  playerSelect: { id: -1, value: "All" },
  platformSelect: { id: -1, value: "All" },
  totalValue: 0,
};
const getters = {
  error: (state) => {
    return state.error;
  },
};
const actions = {
  getAll: async (context, params) => {
    if (!params.endDate) params.endDate = params.startDate;
    let url = `/admin/GameLogs/modeLogs?startDate=${params.startDate}&endDate=${params.endDate}&p=${params.page}&t=50`;
    if (params.betLevel) url += `&betLevel=${params.betLevel}`;
    if (params.modeType - 999) url += `&modeType=${params.modeType}`;
    if (params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.get(url)).data;

    context.commit("getAll", {
      values: d,
      modeType: params.modeType,
      betLevel: params.betLevel,
    });
  },
};
const mutations = {
  getAll(state, data) {
    state.all = data.values;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
