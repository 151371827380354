import Api from "../baseApi";

const state = {
  detail: null,
  user: null,
  fields: [],
};

const actions = {
  getAll: async (context, params) => {
    let url = `/admin/users/Query?p=${params.page}&t=100&status=${params.status}`;
    if (params.fieldsSelect != "-1" || params.field)
      url += `&field=${params.field || params.fieldsSelect}&value=${
        params.value
      }`;
    if (params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
    return d;
  },
  postAll: async (context, params) => {
    let url = `/admin/users/Query?p=${params.page}&t=${params.take}&status=${params.status}`;
    if (params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.post(url, params.body)).data;
    context.commit("getAll", d);
    return d;
  },
  getUserFields: async (context) => {
    let url = `/admin/users/Fields`;

    let d = (await Api.get(url)).data;
    context.commit("getUserFields", d);
    return d;
  },
  getById: async (context, id) => {
    let d = (await Api.get(`/admin/users/${id}`)).data;
    context.commit("getById", d);
  },
  kickUser: async (context, data) => {
    await Api.get(`/admin/users/KickUser/${data.userId}/${data.time}`);
  },
  updateUserDetail: async (context, data) => {
    let url = `/admin/users/UpdateUserDetail/`;
    await (await Api.put(url, data)).data;
  },
};
const mutations = {
  getUserFields(state, fields) {
    state.fields = [{ k: "Id", v: "Id" }, ...fields];
  },
  getAll(state, settings) {
    state.all = settings;
  },
  getById(state, data) {
    state.user = data == "" ? null : data;
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
