import Api from "./baseApi";

const state = {
  detail: {},
  all: [],
};

const actions = {
  getAll: async (context, params) => {
    let url = `/admin/GameModes?p=${params.page}&t=100&`;
    // if (params.options.sortBy[0])
    //   url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
    return d;
  },
  post: async (context, params) => {
    let url = `/admin/GameModes`;
    let d = (await Api.post(url, params)).data;
    return d;
  },
  put: async (context, params) => {
    let url = `/admin/GameModes`;
    let d = (await Api.put(url, params)).data;
    return d;
  },
  getById: async (context, id) => {
    let d = (await Api.get(`/admin/GameModes/${id}`)).data;
    context.commit("getById", d);
  },
  deleteById: async (context, id) => {
    let url = `/admin/GameModes/${id}`;
    await Api.delete(url).data;
  },
  get: async (context, id) => {
    context.commit("get", id);
  },
};
const mutations = {
  getAll(state, settings) {
    state.all = settings;
  },
  getById(state, data) {
    state.detail = data;
  },
  get(state, id) {
    state.detail = state.all.data.find((m) => m.i == id);
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
