import Api from "../baseApi";

const state = {
  all: [],
  detail: {},
  country: [],
  report: [],
  countrySales: {},
  countrySalesList: {},
  city: [],
  dailySales: [],
  platformTotalSales: [],
  userReportSelect: { key: 0, value: "Active" },
  bannedReasonSelect: { key: -1, value: "All" },
  citySelect: { id: "0", value: "All" },
  countrySelect: { id: "0", value: "All" },
};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  async chatDetail(context, params) {
    this.isChipDown = false;
    await this.$store.dispatch("users/getById", params.userId);
    this.user = this.$store.state.users.user;
    this.chipAmount = this.$store.state.users.user.chipAmount;
    this.userDetail = {
      userId: params.userId,
      reports: this.$store.state.userReports.report,
    };
    this.chatDialog = true;
  },
  getAll: async (context, params) => {
    let url = `/admin/Reports?q=${params.search}&p=${params.page}&t=100`;
    url += `&status=${state.userReportSelect.key}`;
    if (state.bannedReasonSelect.key > -1)
      url += `&reason=${state.bannedReasonSelect.key}`;
    if (params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
    return d;
  },
  getById: async (context, id) => {
    let d = (await Api.get(`/admin/Reports/${id}`)).data;
    context.commit("getById", d);
  },
  postBannedToUser: async (context, data) => {
    let d = (await Api.post(`/user/UserBanned`, data)).data;
    // eslint-disable-next-line no-console
    console.log(d);
    // context.commit("postBannedToUser", d);
  },
  bulkUserBan: async (context, data) => {
    let d = (await Api.post(`/user/UserBanned/bulkUserBan`, data)).data;
    // eslint-disable-next-line no-console
    console.log(d);
    // context.commit("postBannedToUser", d);
  },

  chatBanned: async (context, data) => {
    (await Api.post(`/admin/Reports/chatBanned/${data.userId}/${data.timeLeft}/${data.gold}`)).data;
  },
  getPlatformTotalSales: async (context) => {
    let url = `/ product / management / GetPlatformTotalSales`;
    let d = (await Api.get(url)).data;
    context.commit("getPlatformTotalSales", d);
  },
  getByCountrySales: async (context) => {
    let url = `/ product / management / GetByCountrySales`;
    let d = (await Api.get(url)).data;
    context.commit("getByCountrySales", d);
  },
  getCountry: async (context) => {
    let d = (await Api.get(`/ product / purchases / country`)).data;
    context.commit("getCountry", d);
  },
  getCity: async (context) => {
    let d = (await Api.get(`/ product / purchases / city`)).data;
    context.commit("getCity", d);
  },
  get: async (context, id) => {
    context.commit("get", id);
  },
};
const mutations = {
  getPlatformTotalSales(state, settings) {
    state.platformTotalSales = settings;
  },
  getByCountrySales(state, settings) {
    state.countrySalesList = settings;
    settings.forEach((element) => {
      state.countrySales[(element.countryCode || "").toUpperCase()] =
        element.total;
    });
    // eslint-disable-next-line no-console
    console.log(state.countrySales);
  },
  getByDailySales(state, settings) {
    state.dailySales = settings;
  },
  getAll(state, settings) {
    state.all = settings;
  },
  getById(state, settings) {
    state.report = settings;
  },

  getCountry(state, country) {
    state.country = [{ id: "0", value: "All" }, ...country];
  },
  getCity(state, city) {
    state.city = [{ id: "0", value: "All" }, ...city];
  },
  get(state, id) {
    state.detail = state.all.data.find((m) => m.id == id);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
