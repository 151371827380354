import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/lobby",
    name: "lobby",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/lobby/Index.vue"),
  },
  {
    path: "/topusers",
    name: "topusers",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/users/TopUsers.vue"),
  },
  {
    path: "/logs/errors",
    name: "errors",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/errors/Index.vue"),
  },
  {
    path: "/logs/userquery",
    name: "userquery",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/logs/UserQuery.vue"),
  },

  {
    path: "/config/webgl",
    name: "webgl",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/webgl/Index.vue"),
  },
  {
    path: "/purchases",
    name: "purchases",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/purchases/Index.vue"),
  },
  {
    path: "/logs/userReports",
    name: "userReports",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/userReports/Index.vue"),
  },
  {
    path: "/logs/gameLogs",
    name: "gameLogs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/gameLogs/Index.vue"),
  },
  {
    path: "/logs/modeLogs",
    name: "modeLogs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/modeLogs/Index.vue"),
  },
  {
    path: "/logs/modeAggregateLogs",
    name: "modeAggregateLogs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/modeAggregateLogs/Index.vue"),
  },
      
        {
          path: "/logs/userModeAggregateLogs",
          name: "userModeAggregateLogs",
          meta: {
            authRequired: true,
          },
          component: () => import("../views/userModeAggregateLogs/Index.vue"),
        },

  {
    path: "/logs/levelUserCountLogs",
    name: "levelUserCountLogs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/levelUserCountLogs/Index.vue"),
  },
  {
    path: "/pictureUploads",
    name: "pictureUploads",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pictureUploads/Index.vue"),
  },
  {
    path: "/rooms",
    name: "rooms",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/rooms/Index.vue"),
  },
  {
    path: "/config/apiServer",
    name: "apiServer",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/apiServer/Index.vue"),
  },
  {
    path: "/config/settings",
    name: "settings",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/settings/Index.vue"),
  },
  {
    path: "/config/swagger",
    name: "swagger",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/settings/Swagger.vue"),
  },
  {
    path: "/rooms/detail/:id",
    name: "rooms",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/rooms/Detail.vue"),
  },
  {
    path: "/players",
    name: "players",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/players/Index.vue"),
  },
  {
    path: "/logs/playersIo",
    name: "playersIo",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/players/PlayerIo.vue"),
  },
  {
    path: "/players/detail/:id",
    name: "players",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/players/Detail.vue"),
  },
  {
    path: "/config/servers",
    name: "servers",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/servers/Index.vue"),
  },
  {
    path: "/products",
    name: "products",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/products/Index.vue"),
  },
  {
    path: "/seasonPass",
    name: "seasonPass",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/seasonPass/Index.vue"),
  },
  {
    path: "/seasonPass/detail/:id",
    name: "seasonPass",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/seasonPass/Detail.vue"),
  },
  {
    path: "/seasonPass/detail",
    name: "seasonPass",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/seasonPass/Detail.vue"),
  },
  {
    path: "/seasonPass/new",
    name: "seasonPass",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/seasonPass/New.vue"),
  },
  {
    path: "/slotEvents",
    name: "slotEvents",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/slotEvents/Index.vue"),
  },
  {
    path: "/slotEvents/detail/:id",
    name: "slotEvents",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/slotEvents/Detail.vue"),
  },
  {
    path: "/slotEvents/detail",
    name: "slotEvents",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/slotEvents/Detail.vue"),
  },

  {
    path: "/gameModes",
    name: "gameModes",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/gameModes/Index.vue"),
  },
  {
    path: "/nowTrend",
    name: "NowTrend",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/nowTrend/Index.vue"),
  },
  {
    path: "/nowTrend/new",
    name: "NowTrend",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/nowTrend/New.vue"),
  },
  {
    path: "/nowTrend/detail/:id",
    name: "NowTrend",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/nowTrend/Detail.vue"),
  },

  {
    path: "/modeRtp",
    name: "ModeRtp",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/modeRtp/Index.vue"),
  },

  {
    path: "/userChipMovements",
    name: "UserChipMovements",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/userChipMovements/Index.vue"),
  },

  {
    path: "/userChipLogs",
    name: "UserChipLogs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/userChipLogs/Index.vue"),
  },

  {
    path: "/userAuthLogs",
    name: "UserAuthLogs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/userAuthLogs/Index.vue"),
  },

  {
    path: "/campaign",
    name: "Campaign",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/campaigns/Index.vue"),
  },
  {
    path: "/campaign/new",
    name: "Campaign",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/campaigns/New.vue"),
  },
  {
    path: "/campaign/detail/:id",
    name: "Campaign",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/campaigns/Detail.vue"),
  },

  {
    path: "/retentions",
    name: "Retention",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/retentions/Index.vue"),
  },
  {
    path: "/analytics",
    name: "Mode Analytics",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/modeAnalytics/Index.vue"),
  },

  
  {
    path: "/purchaseLogs",
    name: " Purchase Logs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/purchaseLogs/Index.vue"),
  },

  {
    path: "/lostUsers",
    name: "Lost Users",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/lostUsers/Index.vue"),
  },

  {
    path: "/notifications",
    name: "notifications",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/notifications/Index.vue"),
  },
  {
    path: "/logs/chatLogs",
    name: "chatLogs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/gameLogs/chatLog.vue"),
  },
  {
    path: "/logs/specialWinner",
    name: "specialWinner",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/gameLogs/SpecialWinner.vue"),
  },
  {
    path: "/logs/bonus",
    name: "BonusDetailCard",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/logs/components/BonusDetailCard.vue"),
  },

  {
    path: "/logs/votekick",
    name: "votekick",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/gameLogs/VoteKick.vue"),
  },
  {
    path: "/logs/taxpays",
    name: "taxpays",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/users/taxPays/Index.vue"),
  },
  {
    path: "/logs/cashpays",
    name: "cashpays",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/users/cashPays/Index.vue"),
  },
  {
    path: "/logs/amountIo",
    name: "amountIo",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/logs/amountIo/Index.vue"),
  },

  {
    path: "/config/configs",
    name: "configs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/settings/Config.vue"),
  },
  {
    path: "/config/dealer",
    name: "configs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/settings/Dealer.vue"),
  },
  {
    path: "/botDetection",
    name: "botDetection",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/botDetection/Index.vue"),
  },
  {
    path: "/botDetectionv2",
    name: "botDetectionv2",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/botDetection/IndexV2.vue"),
  },
  {
    path: "/logs/feedbacks",
    name: "feedbacks",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/users/feedbacks/Index.vue"),
  },
  {
    path: "/logs/chipTransfers",
    name: "chipTransfers",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/users/components/ChipDetailCard.vue"),
  },
  {
    path: "/users/sitBlocks",
    name: "sitBlocks",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/users/sitBlocks/Index.vue"),
  },
  {
    path: "/products/detail/:id",
    name: "products",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/products/Detail.vue"),
  },
  {
    path: "/products/detail",
    name: "products",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/products/Detail.vue"),
  },
  {
    path: "/gameModes/detail/:id",
    name: "gameModes",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/gameModes/Detail.vue"),
  },
  {
    path: "/gameModes/detail",
    name: "gameModes",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/gameModes/Detail.vue"),
  },
  {
    path: "/notifications/detail/:id",
    name: "notifications",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/notifications/Detail.vue"),
  },
  {
    path: "/notifications/detail",
    name: "notifications",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/notifications/Detail.vue"),
  },
  {
    path: "/config/servers/detail/:id",
    name: "servers",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/servers/Detail.vue"),
  },
  {
    path: "/sign-in",
    name: "signin",
    component: () => import("../views/Signin.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (!store.state.isAuthenticated) {
      next({
        path: "/sign-in",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
