import Api from "./baseApi";

const state = {
  detail: {},
  all: [],
};

const actions = {
  getAll: async (context) => {
    let url = `/admin/GameLogs/modeRtpDetails`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
    return d;
  }
};
const mutations = {
  getAll(state, settings) {
    state.all = settings;
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
