<template>
  <div>
    <div class="row">
      <v-col cols="12" sm="3">
        <div class="text-nowrap">
          <b style="color: #fcc203;"> Total Chip: </b>
          {{
            $store.state.homes.userTotals.reduce((a, b) => a + b.totalChip, 0)
              | formatMoney
          }}
        </div>
        <div class="text-nowrap">
          <b style="color: #fcc203;"> Total Gems: </b>
          {{
            $store.state.homes.userTotals.reduce((a, b) => a + b.totalGems, 0)
              | formatMoney
          }}
        </div>
        <div class="text-nowrap">
          <b style="color: #fcc203;"> Total User: </b>
          {{
            $store.state.homes.userTotals.reduce((a, b) => a + b.totalUser, 0)
              | formatMoney
          }}
        </div>
        <div class="text-nowrap">
          <b style="color: #fcc203;"> Avg User Chip: </b>
          {{
            ($store.state.homes.userTotals.reduce(
              (a, b) => a + b.totalChip,
              0
            ) /
              $store.state.homes.userTotals.reduce(
                (a, b) => a + b.totalUser,
                0
              ))
              | formatMoney
          }}
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="text-nowrap" :key="index">
          <b style="width:110px;display: inline-block;color: #fcc203;"> Platform</b>
          <b style="width:80px;display: inline-block;color: #fcc203;">UserTotal</b>
          <b style="width:190px; display: inline-block;color: #fcc203;">TotalChip</b>
          <b style="width:190px; display: inline-block;color: #fcc203;">TotalGems</b>
        </div>
        <div
          class="text-nowrap"
          :key="index"
          v-for="(item, index) in $store.state.homes.userTotals"
        >
          <span style="width:110px;display: inline-block;">
            {{ item.snId | ToSn }}:</span
          >
          <span style="width:80px;display: inline-block;">{{
            item.totalUser
          }}</span>
          <span style="width:190px; display: inline-block;">{{
            item.totalChip | formatMoney
          }}</span>
          <span style="width:190px; display: inline-block;">{{
            item.totalGems | formatMoney
          }}</span>
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <div class="text-nowrap">
          <b style="color: #fcc203;"> Daily Active Users : </b>
          {{ $store.state.homes.activeUsers[0] }}
        </div>
        <div class="text-nowrap">
          <b style="color: #fcc203;"> Weekly Active Users : </b>
          {{
            $store.state.homes.activeUsers[1]
          }}
        </div>
        <div class="text-nowrap">
          <b style="color: #fcc203;"> Monthly Active Users : </b>
          {{
            $store.state.homes.activeUsers[2]
          }}
        </div>
        <div class="text-nowrap">
          <b style="color: #fcc203;"> Stickiness Rate : </b>
          {{
            $store.state.homes.activeUsers[3] +"%"
          }}
        </div>
      </v-col>
      <v-col cols="12" sm="1"></v-col>
    </div>
    <v-row>
      <v-col cols="12" lg="6" class="pt-8">
        <MapChart
          :countryData="mapData"
          highColor="#ff0000"
          lowColor="#aaaaaa"
          countryStrokeColor="#909090"
          defaultCountryFillColor="#dadada"
        />
        <v-data-table
          :headers="headers"
          :items="countrySalesList"
          :sort-by="['total']"
          :sort-desc="[true]"
          :items-per-page="1000"
          class="elevation-1"
        >
          <template v-slot:item.name="{ item }">
            <div>{{ item.name | toCountry }}</div>
          </template>

          <template v-slot:item.uniqueUserCount="{ item }">
            <div>
              {{ item.uniqueUserCount }} / $
              {{ (item.total / item.uniqueUserCount) | formatMoney }} /
              {{ (item.totalOrder / item.uniqueUserCount) | formatMoney }}
            </div>
          </template>

          <template v-slot:item.totalChip="{ item }">
            <div>{{ item.totalChip | formatMoney }}</div>
          </template>
          <template v-slot:item.total="{ item }">
            <div>$ {{ item.total | formatMoney }}</div>
          </template>
          <template v-slot:item.avgSales="{ item }">
            <div>$ {{ (item.total / item.totalOrder) | formatMoney }}</div>
          </template>
          <template v-slot:item.avgChip="{ item }">
            <div>{{ (item.totalChip / item.totalOrder) | formatMoney }}</div>
          </template>
          <template v-slot:item.countryCode="{ item }">
            <div style="display: flex;">
              <v-avatar size="36" style="margin-right:5px; margin-top:3px;">
                <v-img
                  :src="
                    `https://bundle.slotbase.net/flags/${item.countryCode}.png`
                  "
                />
              </v-avatar>
              <div>
                <span>{{ item.name }}</span>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" lg="6" class="pt-8">
        <MaterialChartCard
          :data="dailySalesChart.data"
          :options="dailySalesChart.options"
          color="success"
          hover-reveal
          type="Line"
        >
          <h4 class="card-title font-weight-light mt-2 ml-2">
            Daily Sales Last 30 Day
          </h4>
        </MaterialChartCard>
        <v-row>
          <v-col cols="12" lg="3">
            <v-toolbar color="blue darken-4" flat dark>
              <v-toolbar-title>
                <span class="yellow--text" style="margin-right:1px"
                  >Sales: $
                  {{
                    this.purchases.reduce((a, b) => a + b.total, 0)
                      | formatMoney
                  }}</span
                >
              </v-toolbar-title>
            </v-toolbar>
            <div v-for="(item, i) in this.purchases" :key="i">
              <v-card max-width="400" class="mx-auto" dark>
                <v-card-text>
                  <p class="title white--text text-center">
                    {{ item.name }} - ${{ item.total | formatMoney }}
                  </p>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12" lg="5">
            <v-toolbar color="red darken-4" flat dark>
              <v-toolbar-title>
                <span class="yellow--text" style="margin-right:10px"
                  >Total Bonus: $
                  {{
                    this.$store.state.homes.bonusesGroup.reduce(
                      (a, b) => a + b.total.value,
                      0
                    ) | formatMoney
                  }}</span
                >
              </v-toolbar-title>
            </v-toolbar>
            <div
              v-for="(item, i) in this.$store.state.homes.bonusesGroup"
              :key="i"
            >
              <v-card max-width="400" class="mx-auto" dark>
                <v-card-text>
                  <p class="title white--text font-weight-bold text-center">
                    {{ item.key }} - {{ item.doc_count }} - $
                    {{ item.total.value | formatMoney }}
                  </p>
                </v-card-text>
              </v-card>
            </div>
          </v-col>

          <v-col cols="12" lg="4">
            <v-toolbar color="green darken-4" flat dark>
              <v-toolbar-title>
                <span class="yellow--text" style="margin-right:10px"
                  >Daily New Users:
                  {{
                    this.$store.state.homes.registerUsersGroup.reduce(
                      (a, b) => a + b.doc_count,
                      0
                    ) | formatMoney
                  }}</span
                >
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="green darken-4">
              <p
                v-for="(item, i) in this.$store.state.homes
                  .registerUsersGroupTotal"
                :key="i"
              >
                Total {{ item.key | ToSn }} -
                <span style="color:#f4ff49;">{{ item.value }}</span>
              </p>
            </v-card-text>
            <div
              v-for="(item, i) in this.$store.state.homes.registerUsersGroup"
              :key="i"
            >
              <v-card max-width="400" class="mx-auto" dark>
                <v-card-text>
                  <p class="display-1 text--primary">
                    {{ item.key }} -
                    <span style="color:#f4ff49;">{{ item.doc_count }}</span>
                  </p>
                  <p v-for="(itemx, a) in item.platformInfo.buckets" :key="a">
                    {{ itemx.key | ToSn }} -
                    <span style="color:#f4ff49;">{{ itemx.doc_count }}</span>
                  </p>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import MaterialChartCard from "@/components/MaterialChartCard";
// eslint-disable-next-line no-unused-vars
import MapChart from "vue-map-chart";

export default {
  components: {
    MaterialChartCard,
    MapChart,
  },

  data() {
    return {
      mapData: {},
      countrySalesList: [],
      dailySalesChart: {
        data: {
          labels: [],
          series: [[]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 10,
          }),
          low: 0,
          high: 5000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      purchases: [],
      headers: [
        { text: "Location", value: "countryCode" },
        { text: "Total Sales", value: "total" },
        { text: "Avg Sales", value: "avgSales" },
        {
          text: "U.User Count / Per Sales / Avg Orders",
          value: "uniqueUserCount",
        },
        { text: "Total Orders", value: "totalOrder" },
        { text: "Total Chip", value: "totalChip" },
        { text: "Avg Chip", value: "avgChip" },
      ],
    };
  },
  methods: {
    async initialize() {
      let dt = new Date();
      dt.setDate(dt.getDate() - 1);
      let date = Math.round(dt.getTime() / 1000);
      this.$store.dispatch("homes/getRegisterUserCount", {
        startDate: date,
      });
      this.$store.dispatch("homes/getBonuesCount", {
        startDate: date,
      });
      this.$store.dispatch("homes/getGroupSnIdUserTotal");

      await this.$store.dispatch("homes/getActiveUsers");
      
      await this.$store.dispatch("purchases/getByDailySales", {
        startDate: Math.round(new Date().addDays(-29).getTime() / 1000),
        endDate: Math.round(new Date().getTime() / 1000),
      });


      this.dailySalesChart.data.labels = this.$store.state.purchases.dailySales.map(
        (m) => m.name.split("-")[2]
      );
      this.dailySalesChart.data.series = [
        this.$store.state.purchases.dailySales.map((m) => m.total),
      ];

      this.dailySalesChart.options.high = this.dailySalesChart.data.series.sort(
        function(a, b) {
          return a - b;
        }
      )[0];

      await this.$store.dispatch("purchases/getByCountrySales");
      await this.$store.dispatch("purchases/getPlatformTotalSales");
      this.purchases = this.$store.state.purchases.platformTotalSales;
      this.mapData = this.$store.state.purchases.countrySales;
      this.countrySalesList = this.$store.state.purchases.countrySalesList;
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>
<style>
.vue-map-legend {
  color: #000;
}
.vue-world-map {
  height: initial !important;
}
</style>
