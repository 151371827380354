import Api from "./baseApi";
const state = {
  all: [],
  data: [],
  aggregation:[],
  select: { id: -1, value: "All" },
  totalValue: 0,
};
const getters = {
  error: (state) => {
    return state.error;
  },
};
const actions = {
  getAll: async (context, params) => {
    if (!params.endDate) params.endDate = params.startDate;
    if(params.userId===undefined)params.userId=null;
    if(params.productType===undefined)params.productType=null;

    let url = `/admin/GameLogs/GetPurchaseByProductType?userId=${params.userId}&productType=${params.productType}&startDate=${params.startDate}&endDate=${params.endDate}&take=${params.size}&page=${params.page}`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", {
      values: d,
    });
  },
};
const mutations = {
  getAll(state, data) {
    // eslint-disable-next-line no-console
    console.log("data=",data.values.aggregation);
    state.aggregation = data.values.aggregation;
    state.data=data.values.data;
  },
  getAllWithQuery(state, data) {
    state.all = data.values;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
