import Api from "./baseApi";

const state = {
  detail: {},
  all: [],
};

const actions = {
  getAll: async (context, params) => {
    let url = `/admin/TrendModes/getAll?p=${params.page}&t=50&`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
    return d;
  },
  post: async (context, params) => {
    // eslint-disable-next-line no-console
    console.log(params);
    let url = `/admin/TrendModes/post`;
    let d = (await Api.post(url, params)).data;
    return d;
  }, 
  update: async (context, params) => {
    let url = `/admin/TrendModes/update`;
    let d = (await Api.put(url, params)).data;
    // eslint-disable-next-line no-console
    console.log("here",d);
    return d;
  },
  getById: async (context, id) => {
    let d = (await Api.get(`/admin/TrendModes/getById/${id}`))
      .data;
    context.commit("getById", d);
  },
  deleteById: async (context, id) => {
    let url = `/admin/TrendModes/deleteById/${id}`;
    await Api.delete(url).data;
  },
};
const mutations = {
  getAll(state, settings) {
    state.all = settings;
  },
  getById(state, data) {
    state.detail = data
    
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
