import Api from "./baseApi";

const state = {
  detail: {},
  all: [],
};

const actions = {
  getAll: async (context, params) => {
    let url = `/admin/SeasonPass/All?p=${params.page}&t=50&`;

    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
    return d;
  },
  post: async (context, params) => {
    // eslint-disable-next-line no-console
    console.log(params);
    let url = `/admin/SeasonPass`;
    let d = (await Api.post(url, params)).data;
    return d;
  },
  put: async (context, params) => {
    let url = `/admin/SeasonPass`;
    let d = (await Api.put(url, params)).data;
    return d;
  },
  getById: async (context, id) => {
    let d = (await Api.get(`/admin/SeasonPass/${id}`))
      .data;
    context.commit("getById", d);
  },
  deleteById: async (context, id) => {
    let url = `/admin/SeasonPass/${id}`;
    await Api.delete(url).data;
  },
  get: async (context, id) => {
    context.commit("get", id);
  },
};
const mutations = {
  getAll(state, settings) {
    state.all = settings;
  },
  getById(state, data) {
    state.detail = data;
  },
  // get(state, id) {
  //   state.detail = state.all.data.find((m) => m.id == id);
  // },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
