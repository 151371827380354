import Api from "../baseApi";
const state = {
  all: [],
  export: {},
  config: {},
};
const getters = {
  config: (state) => {
    return state.config;
  },
};

const actions = {
  getAll: async (context, env) => {
    let url = `/admin/configs/${env}`;
    let d = (await Api.get(url)).data;
    context.commit("getConfigs", d);
  },
  getExport: async (context, env) => {
    let url = `https://config.slotbase.net/${env}`;
    let d = (await Api.get(url)).data;
    return d;
  },
  postConfigs: async (context, data) => {
    (await Api.post(`/admin/configs/`, data)).data;
    context.commit("postConfigs", data);
  },
  delete: async (context, id) => {
    (await Api.delete(`/admin/configs/${id}`)).data;
  },
};
const mutations = {
  getConfigs(state, Configs) {
    state.all = Configs;
  },
  getExport(state, Configs) {
    state.export = Configs;
  },
  postConfigs(state, Configs) {
    state.config = Configs;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
