import Api from "./baseApi";

const state = {
  retention: [],
  all: [],
  agg: []
};

const actions = {
  get: async (context,params) => {
    
    let url = `/admin/Retention/get?format=${params.format}&startDate=${params.startDate}&endDate=${params.endDate}`;
    let d = (await Api.get(url));
    context.commit("get", d);
    return d;
  }
};
const mutations = {
  get(state, settings) {
    state.all = settings;
    state.agg=settings.data.a
    state.retention=settings.data.r
    // eslint-disable-next-line no-console
    console.log("setting :"+JSON.stringify(settings));
  },
  getById(state, data) {
    state.detail = data
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
