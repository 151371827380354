import Api from "../baseApi";
const state = {
  all: [],
  chatAll: {},
  detail: {},
  voteKickAll: {},
  versions: [],
  select: { id: -1, value: "All" },
  playerSelect: { id: -1, value: "All" },
  platformSelect: { id: -1, value: "All" },
  sitSelect: { id: -1, value: "All" },
  totalValue: 0,
};
const getters = {
  error: (state) => {
    return state.error;
  },
};
const actions = {
  getAll: async (context, params) => {
    let url = `/admin/GameLogs?t=${params.size}&p=${params.page}`;
    if (params.search) url += `&userId=${params.search}`;
    if (params.searchType > -1) url += `&searchType=${params.searchType}`;
    if (params.date > -1) url += `&date=${params.date}`;
    if (params.modeType > -999) url += `&modeType=${params.modeType}`;
    if (params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.get(url)).data;

    context.commit("getAll", { values: d, search: params.search });
  },
  getChatAll: async (context, params) => {
    let url = `/admin/GameLogs/chatList?t=${params.total}&p=${params.page}`;
    if (params.search) url += `&search=${params.search}`;
    if (params.to) url += `&TO=${params.to}`;
    if (params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.get(url)).data;
    context.commit("getChatAll", d);
  },

  getVoteKickAll: async (context, params) => {
    let url = `/admin/GameLogs/votekicklist?t=${params.total}&searchType=${params.searchTypeSelect}&p=${params.page}`;
    if (params.search) url += `&search=${params.search}`;
    if (params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.get(url)).data;
    context.commit("getVoteKickAll", d);
  },

  getDetail: async (context, params) => {
    let d = (
      await Api.post(`https://search-api.slotbase.co/search/log_gamev1`, {
        size: 2000,
        // sort: [
        //   {
        //     s: {
        //       order: "desc",
        //     },
        //   },
        // ],
        query: {
          match: {
            id: `${params}`,
          },
        },
      })
    ).data;
    context.commit("getDetail", d);
  },
};
const mutations = {
  getVersion(state, error) {
    state.versions = error.aggregations.version.buckets.map((obj) => {
      return {
        version: obj.key,
      };
    });
  },
  getChatAll(state, data) {
    state.chatAll = data;
  },
  getVoteKickAll(state, data) {
    data.data.map((m) => {
      if (m.vote.length == 0) {
        m.vote.push({
          userId: "00000",
          flag: "fr",
          pictureUrl:
            "https://bundle.slotbase.net/2021/01/15/7a04ae6c6fce8d01acb94994aabc64df",
          userName: "Test",
          level: 111,
        });
      }
    });
    state.voteKickAll = data;
  },
  getAll(state, data) {
    // data.values.data.map(m => {
    //   m.user = m.ss.find(n => n.i == data.search);
    //   if (m.t == 2 || m.t == 3) {
    //     m.ss.push({
    //       s: -1,
    //       i: "Dealer",
    //       c: [m.c],
    //       v: [
    //         138821770,
    //         258821770,
    //         m.v[9],
    //         m.v[9],
    //         0,
    //         0,
    //         1,
    //         0,
    //         0,
    //         0,
    //         0,
    //         0,
    //         0,
    //         0,
    //         0,
    //       ],
    //       f: "tr",
    //       pu: "https://bundle.slotbase.net/assets/img/dealer.png",
    //     });

    //     m.ss = m.ss.reverse();
    //   }
    //   if (m.t == 4) {
    //     m.ss.push({
    //       s: -1,
    //       i: "Dealer",
    //       c: [m.dc],
    //       v: [
    //         138821770,
    //         258821770,
    //         m.v[9],
    //         m.v[9],
    //         0,
    //         0,
    //         1,
    //         0,
    //         0,
    //         0,
    //         0,
    //         0,
    //         0,
    //         0,
    //         0,
    //       ],
    //       f: "tr",
    //       pu: "https://bundle.slotbase.net/assets/img/dealer.png",
    //     });

    //     m.ss = m.ss.reverse();
    //   }

    // });

    state.all = data.values;
  },
  getParameters() {
    const prefix = "players.";
    ["select", "playerSelect", "sitSelect", "platformSelect"].forEach(
      (element) => {
        var elem = localStorage.getItem(prefix + element);
        if (elem) state[element] = JSON.parse(elem);
      }
    );
  },
  getDetail(state, player) {
    state.detail = player.hits.hits.map((obj) => {
      return obj._source;
    });
  },
  getPlayerOut(state, player) {
    // eslint-disable-next-line no-unused-vars
    player = player.aggregations.group_by_user.buckets.map((obj) => {
      return obj.top_user_hits.hits.hits[0]._source;
    });
    player.map((a) => {
      if (a.picture && a.picture.startsWith("/"))
        a.picture = "https://bundle.slotbase.net" + a.picture;
      else if (a.picture && !a.picture.startsWith("http"))
        a.picture = "https://bundle.slotbase.net/assets/Avatars/" + a.picture;

      if (a.bonusRewards)
        a.bonusRewardsCount = a.bonusRewards.reduce((a, b) => a + b.chip, 0);
    });
    state.playersOut = player;
  },
  getPlayerOutByUserId(state, player) {
    player = player.hits.hits.map((obj) => {
      return obj._source;
    });
    player.map((a) => {
      if (a.picture && a.picture.startsWith("/"))
        a.picture = "https://bundle.slotbase.net" + a.picture;
      else if (a.picture && !a.picture.startsWith("http"))
        a.picture = "https://bundle.slotbase.net/assets/Avatars/" + a.picture;
      if (a.bonusRewards)
        a.bonusRewardsCount = a.bonusRewards.reduce((a, b) => a + b.chip, 0);
    });
    state.playersOutDetail = player;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
