import Api from "../baseApi";
const state = {
  all: {},
  minBuys: [{ key: -1, value: "All" }],
  winAmount: [{ key: -1, value: "All" }],
  select: { id: -1, value: "All" },
  playerSelect: { id: -1, value: "All" },
  platformSelect: { id: -1, value: "All" },
  sitSelect: { id: -1, value: "All" },
  totalValue: 0,
};
const getters = {
  error: (state) => {
    return state.error;
  },
};
const actions = {

  getAll: async (context, params) => {
    let url = `/admin/SpecialWinners?mode=${params.slotMode}&t=${params.total}&p=${params.page}`;
    if (params.search)
      url += `&q=${params.search}`;
    if (params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;
    if (params.minBuyIn)
      url += `&minBuyIn=${params.minBuyIn}`;
    if (params.winAmount)
      url += `&winAmount=${params.winAmount}`;
    let d = (
      await Api.get(url)
    ).data;
    context.commit("getAll", d);
  },
};
const mutations = {
  getAll(state, data) {
    state.all = data;
    state.minBuys = [...state.minBuys, ...Array.from(new Set(data.data.map(m => m.m))).map((a) => {
      return { key: a, value: a };
    })]

    state.winAmount = [...state.winAmount, ...Array.from(new Set(data.data.map(m => m.w))).map((a) => {
      return { key: a, value: a };
    })]
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
