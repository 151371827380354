export default [
  { value: "Active", key: 0 },
  { value: "Picture", key: 1 },
  { value: "Username", key: 2 },
  { value: "Chat", key: 3 },
  { value: "IllegalTranfer", key: 4 },
  { value: "ChipSeller", key: 5 },
  { value: "Bot", key: 6 },
  { value: "TeamPlay", key: 7 },
];
