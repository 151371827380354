import Api from "./baseApi";
import baseStore from "../index";
import lodash from "lodash";
import slotMode from "@/lib/slotMode";

const state = {
  all: [],
  lobby: {},
  playerCount: 0,
  botCount: 0,
};
const getters = {
  lobby: (state) => {
    return state.lobby;
  },
};
const actions = {
  getLobbies: async (context) => {
    let url = `${baseStore.state.server.url}/api/admin/Lobbies`;
    let d = (await Api.get(url)).data;
    context.commit("getLobbies", d);
  },
  postBotLimit: async (context, params) => {
    await Api.post(
      `${baseStore.state.server.url}/api/admin/Lobbies/PostBotLimit/${params.type}`,
      params
    );
    context.dispatch("getLobbies");
  },

  modesChanged: async (context, item) => {
    (
      await Api.post(`/admin/ModeItems/modesChanged`, item)
    ).data;
  },
  changeEnabled: async (context, item) => {
    (
      await Api.post(
        `/admin/ModeItems/ModeChanged/${item.id}`, item
      )
    ).data;
  },
  syncMode: async () => {
    (
      await Api.post(
        `/admin/ModeItems/SyncMode`
      )
    ).data;
  },
};
const mutations = {
  getLobbies(state, lobby) {
    const a = lodash.groupBy(lobby, "mode");
    var data = [];
    for (const property in a) {
      data.push({
        title: slotMode.find(m => m.key == property) != null ? slotMode.find(m => m.key == property).value : property,
        data: a[property],
        players: a[property].reduce((a, b) => a + b.players, 0),
      });
    }
    state.players = data.reduce((a, b) => {
      return a + b.players;
    }, 0);
    state.all = data;
    // eslint-disable-next-line no-console
    console.log(data);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
